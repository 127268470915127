#sidebar {
    width: 250px;
    height: 100%;
    min-height: 100vh;
    background-color: $sidebar-bg;
    // background-image: url('../../images/pattern.png');
    // background-repeat: repeat;
    min-width: 250px;
    position: sticky;
    bottom: 0;
    top: 0;

    .logo {
        margin: 10px;
        text-align: center;

        img {
            height: auto;
            padding: 20px 0px 0px 0px;
            max-width: 100%;
        }
    }

    ul li {
        font-size: 14px;
        margin-left: 10px;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        margin-bottom: 5px;

        &.active {
            .item a {
                padding: 10px 25px 10px 25px;
                background: $main-bg;
                border-top-left-radius: 20px;
                border-bottom-left-radius: 20px;
                display: flex;
                color: $sidebar-font-active;
                transition: .2s;
                width: 100%;
            }
        }

        &:not(.active){
            .item a {
                padding: 10px 25px 10px 10px;
                transition: .2s;
                display: flex;
                width: 100%;

                &:hover {
                    background: $white;
                    padding: 10px 25px 10px 25px;
                    border-top-left-radius: 20px;
                    border-bottom-left-radius: 20px;
                    transition: .2s;
    
                    .icon path {
                        color: $sidebar-font-inactive;
                    }
                }
            }
        }

        .icon {
            height: 22px;
            width: 22px;
            margin-right: 10px;

            svg {
                height: 100%;
                width: 100%;

                path {
                    color: $sidebar-font-inactive;
                }
            }

            &.active {
                color: $sidebar-font-active;

                svg {
                    color: $sidebar-font-active;
                    path {
                        color: $sidebar-font-active;
                    }
                }
            }
        }
            
        a {
            text-decoration: none;
            color: $sidebar-font-inactive;
        }

        .submenu {
            margin-left: 25px;
            margin-top: 5px;
            margin-bottom: 5px;

            li {
                a {
                    padding: 7px 0px 7px 0px;
                    color: $sidebar-font-inactive;
                    font-weight: 400;
                    font-size: 14px;
                    display: flex;
                    transition: .2s;
                    width: 100%;

                    &:hover {
                        padding: 7px 25px 7px 15px;
                        background: $primary;
                        border-top-left-radius: 20px;
                        border-bottom-left-radius: 20px;
                        width: 100%;
                        transition: .2s;
                    }
                }

                &.active a {
                    color: $sidebar-font-active;
                    font-weight: 500;
                    display: flex;
                    width: 100%;
                    transition: .2s;
                    background: $primary;
                    border-top-left-radius: 20px;
                    border-bottom-left-radius: 20px;
                    padding: 7px 25px 7px 15px;
                }
            }
        }
    }
}