.tooltip-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .tooltip {
        position: absolute;
        background: $tooltip-bg;
        padding: 2px 12px;
        border-radius: 5px;
        color: $white;
        font-size: 12px;
        transition: .2s;
        width: max-content;
        opacity: 0;
        z-index: 100;
        user-select: none;
        top: -30px;

        &.show {
            opacity: 1;
            transition: .2s;
        }

        &.bottom {
            // transform: translate(-50%, 110%)!important;
            top: 35px;
        }

        &.padding-fix {
            padding: 12px 12px;
        }
    }
}