.snackbar {
    background: $snackbar;
    position: absolute;
    z-index: 99;
    top: -200px;
    margin-left: 50px;
    padding: 12px 0 12px 25px;
    color: $white;
    user-select: none;
    transition: .2s;
    margin-left: 50px;
    width: calc(100vw - 450px);

    &.max-width {
        margin-left: 27px;
        margin-right: 25px;
        width: calc(100vw - 50px);
        align-self: center;

        @include lg {
            width: calc(50vw - 50px);
        }
    }

    &.open {
        top: 50px;
        transition: .2s;
    }

    .wrapper {
        width: 100%;
    }

    .content {
        .text {
            display: block;
            margin-left: 5px;
            color: $white;
            line-height: 20px;
        }
    
        svg {   
            font-size: 20px;
            color: $white;

            path {
                color: $white;
            }
        }
    }

    .close-icon {
        padding: 15px 25px;

        svg {
            color: $white;
            path {
                color: $white;
            }
        }

        &:hover {
            cursor: pointer;

            svg {
                color: $tertiary;
                path {
                    color: $tertiary;
                }
            }
        }
    }
}