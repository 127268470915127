section.login {
    background: $login-bg;
    height: 100%;
    width: 100%;
    background-image: url('../../images/pattern.png');
    background-repeat: repeat;

    .logo {
        user-select: none;
        margin: 0 20px;
    }

    .logo img {
        width: 100%;
        max-width: 300px;
    }

    .login-block {
        background: $white;
        padding: 25px 25px;
        border-radius: 7px;
        width: 400px;
        box-shadow: $box-shadow;
    }

    .forgot-password {
        display: block;
        margin: 10px 0 20px 0;
        text-decoration: none;
        color: $font-alt;
        font-size: 12px;
        text-align: right;
        cursor: pointer;

        &:hover {
            color: $link-hover;
        }
    }

    .error-field {
        text-align: left;
        margin: 5px;
    }
}