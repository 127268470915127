//colors
$white: #FFF;
$black: #000;
$light-grey: #F4F5F7;
$med-grey: #e6e6e6;

$primary: #F5F9F9;
$secondary: #E5F5F6;
$tertiary: #99D9DA;
$dark-turq: #6eb6b6;
$quaternary: #fbfbfb;

$mandatory-star: #217c7c;

//page bgs
$main-bg: $white;
$login-bg: $primary;

$tooltip-bg: #616060;

//fonts
$font: #1f1e1e;
$font-alt: #7a7777;
$font-alt-2: #6d6d6d;
$font-alt-3: #9f9f9f;
$link: $dark-turq;
$link-hover: $dark-turq;
$font-disabled: #d2d2d2;

//input
$input-text: $font;
$input-label:  $font-alt-3;
$input-label-top: $dark-turq;
$input-disabled-bg: #f8f8f8;
$input-disabled-text: #7a7a7a;
$input-border: $med-grey;
$error-text: #a41f1f;

//button
$bg-btn-disabled: #e2e2e2;
$bg-btn: $dark-turq;
$bg-btn-hover: $tertiary;

//sidebar
$sidebar-bg: $secondary;
$sidebar-font-active: $font;
$sidebar-font-inactive: $font;

//tables
$table-heading: $primary;
$table-borders: $light-grey;
$table-cols: $white;
$table-group: #f3f3f3;

//snackbar
$snackbar: $font;

//general
$box-shadow: 0px 0px 5px #ececec;

//checkbox
$checkbox-border: $table-borders;

//scrollbar modal
$scroll-bar-modal: #dddddd;