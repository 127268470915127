section.page {
    padding: 70px 50px;

    .fourofour {
        text-align: center;

        img {
            height: 250px;
            width: auto;
        }

        .question {
            -webkit-animation: grow 2s linear infinite;
            -moz-animation: grow 2s linear infinite;
            -ms-animation: grow 2s linear infinite;
            -o-animation: grow 2s linear infinite;
            animation: grow 2s linear infinite;

            @keyframes grow {
                0% {
                    transform: scale(1.0);
                    -webkit-transform: scale(1.0);
                }
                50% {
                    transform: scale(1.7);
                    -webkit-transform: scale(1.7);
                }
                100% {
                    transform: scale(1.0);
                    -webkit-transform: scale(1.0);
                }
            }
        }
    }
}