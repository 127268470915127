//Fields
.input-field {
    position: relative;

    .input-wrapper {
        position: relative;

        label {
            position: absolute;
            top: 10px;
            left: 15px;
            color: $input-label;
            pointer-events: none;
            transition: .2s;
        }
        
        .input {
            width: 100%;
            display: block;
            height: 45px;
            border-radius: 5px;
            border: 1px solid $input-border;
            padding: 12px 15px 12px 15px;
            color: $input-text;
            transition: .1s;

            &:focus {
                outline: 0;
            }

            &:not(:placeholder-shown),
            &:focus,
            &:-webkit-autofill{
                padding: 12px 15px 0px 15px;

                + label {
                    top: 3px;
                    transition: .2s;
                    color: $input-label-top;
                    font-size: 11px;
                }
            }
        

            &.disabled,
            &:disabled {
                background: $input-disabled-bg;
                color: $input-disabled-text!important;
            }

            &.no-top-label {
                padding: 0px 15px 2px 15px;

                &:not(:placeholder-shown) + label,
                &:focus + label,
                &:-webkit-autofill + label {
                    display: none;
                }
            }

            &.icon-spacing {
                padding-left: 40px;

                + label {
                    padding-left: 25px;
                }
            }
        }

        &.error {
            input {
                border: 1px solid $error-text;
            }
        }
    }

    .error-text {
        color: $error-text;
        font-size: 12px;
        position: relative;
        left: 5px;
        top: 3px;
    }
    
    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
        border: 1px solid $input-border;
        -webkit-text-fill-color: $black;
        transition: background-color 5000s ease-in-out 0s;
        -webkit-box-shadow: 0 0 0px 1000px $white inset;
        font-size: 14px;
    }

    .icon {
        position: absolute;
        top: 10px;
        left: 10px;

        svg {
            path {
                color: $input-label;
            }
        }
    }
}