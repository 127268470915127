.upload-field {
    input {
       display: none;
    }

    label {
        border: 1px solid $input-border;
        color: $font;
        width: 100%;
        display: block;
        height: 45px;
        border-radius: 5px;
        border: 1px solid $input-border;
        padding: 5px 15px 15px 15px;
        color: $input-text;
        transition: .1s;
        background: $light-grey;
        text-overflow: ellipsis;
        max-width: 200px;
        overflow: hidden;
        white-space: nowrap;

        &:hover {
            cursor: pointer;
            background: $secondary;
        }
    }

    .upload-icon {
        position: relative;
        top: 6px;
        margin-right: 5px;
        font-size: 21px;
    }

    .file-icon {
        position: relative;
        top: 6px;
        margin-right: 5px;
        font-size: 20px;
    }
}