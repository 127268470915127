.mail-template {
    .attachment-wrapper {
        margin: 0 8px;

        svg {
            &:hover {
                cursor: pointer;

                path {
                    color: $tertiary;
                }
            }
        }

        .icon {
            position: relative;
            top: 4px;
            left: 5px;
        }
    }

    .text-area {
        width: 100%;
        resize: none;
        border: 1px solid $table-borders;
        // border-radius: 10px;
        padding: 12px 15px 12px 15px;

        &:focus {
            outline: 0;
        }
    }

    .mail-variables {
        margin: 0px 10px 20px 8px;

        span {
            color: $font-alt-2;
            font-size: 13px;

            &.title {
                color: $font!important;
                font-size: 13px;
            }
        }
    }
}