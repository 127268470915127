.file-upload {
    width: 100%;

    .hidden{
        display: none;
    }

    &:hover {
        cursor: pointer;
    }

    .file-drop {
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;

        .file-drop-target {
            width: 100%;
            height: 100%;
            min-height: 150px;
            display: flex;
            padding: 20px 40px;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            font-weight: 500;
            border: 2px dashed #E2E2E2;

            &.file-drop-dragging-over-target {
                border: 2px dashed #7a7a7a;
            }

            .icon-upload {
                width: 40px;
                margin-bottom: 10px;
            }
        }
    }

    .selected-file {
        font-weight: bold;
    }

    .error-allowed {
        color: $error-text;

        span {
            color: $error-text;
        }
    }
}