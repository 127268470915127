// @import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400&display=swap');

$regular: 'Mulish-Regular';
$medium: 'Mulish-Medium';
$semibold: 'Mulish-SemiBold';
$bold: 'Mulish-Bold';
$italic: 'Mulish-Italic';
$light: 'Mulish-Light';

@font-face {
    font-family: $regular;
    src: url('../fonts/Mulish-Regular.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: $medium;
    src: url('../fonts/Mulish-Medium.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: $semibold;
    src: url('../fonts/Mulish-SemiBold.ttf') format('ttf');
    font-weight: semibold;
    font-style: normal;
}
@font-face {
    font-family: $bold;
    src: url('../fonts/Mulish-Bold.ttf') format('ttf');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: $italic;
    src: url('../fonts/Mulish-Italic.ttf') format('ttf');
    font-weight: normal;
    font-style: italic;
}
@font-face {
    font-family: $light;
    src: url('../fonts/Mulish-Light.ttf') format('ttf');
    font-weight: light;
    font-style: normal;
}

body, * {
    font-family: $regular, sans-serif;
    font-size: 14px;
    line-height: 25px;
    color: $font;
}

h1,
.h1 {
    display: block;
    font-size: 35px;
    font-weight: bold;
    line-height: 55px;
    color: $font;

    &.-alt {
        color: $font;
    }
}

h2,
.h2 {
    display: block;
    font-size: 30px;
    font-weight: bold;
    line-height: 42px;
    color: $font;

    &.-alt {
        color: $font;
    }
}

h3,
.h3 {
    display: block;
    font-size: 20px;
    font-weight: bold;
    line-height: 30px;
    color: $font;

    &.-alt {
        color: $font;
    }
}

h4,
.h4 {
    display: block;
    font-size: 18px;
    font-weight: bold;
    line-height: 27px;
    color: $font;

    &.-alt {
        color: $font;
    }
}